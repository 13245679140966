import React from "react";
import promo1 from "../assets/promo.JPG";
import promo2 from "../assets/promo2.JPG";
import promo3 from "../assets/promo3.JPG";

const promoImages = [
    {
        id: 1,
        src: promo1,
        alt: "Image 1",
    },
    {
        id: 2,
        src: promo2,
        alt: "Image 2",
    },
    {
        id: 3,
        src: promo3,
        alt: "Image 3",
    },
];

export default promoImages;
