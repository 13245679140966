import {Sidebar} from "../Components/index";
import {getTermsAndCondition} from "../Utilities/index"

const TermsConditionsPage = () =>
{
    return (
        <div>
        <div className={`bg-[#1d7df9] mb-2 text-white font-sans text-center py-2`}>
            <h1 className="text-3xl font-bold"><a href="/">BeeLine x Perlego Colour Competition</a></h1>
        </div>
        <div className={"text-left mx-4 my-2 sm:mx-10 sm:my-5 md:mx-14 md:my-5 lg:mx-56 lg:my-5 xl:mx-72 xl:my-6 2xl:mx-80 2xl:my-12"}>
            <Sidebar/>
            <h1 className="text-center mb-4 text-2xl">Terms and Conditions</h1>
            <div className="flex flex-col text-left space-y-3 md:shadow-md px-4 pt-1 pb-3">
                {getTermsAndCondition()}
            </div>
        </div>
        </div>
    );
}

export default TermsConditionsPage;