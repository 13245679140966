import React from 'react';
import { SketchPicker } from 'react-color';

const Palette = ({hex, setHex}) =>
{
    return (
        <div className="absolute z-10">
          <SketchPicker color={hex} onChange={(color) => {setHex(color.hex)}} presetColors={[]} disableAlpha={true}/>
        </div>
    )
}

export default Palette;