import React, {useState} from 'react';
import {Sidebar, ColorButtons, Paragraph, Socials, CaptureDefaultImage, Footer} from "../Components/index"

const HomePage = () =>
{
    const [isNight, setNight] = useState(Math.random() < 0.5);
    const [hex1, setHex1] = useState(isNight ? presetColors[0][0] : presetColors[1][0]);
    const [hex2, setHex2] = useState(isNight ? presetColors[0][1] : presetColors[1][1]);
    const [isSerif, setSerif] = useState(false);
    const [captureReady, setCapture] = useState(false);
    
  

    return (
      <>
      <div className={`h-screen ${isNight ? "bg-nightMode" : "bg-lightMode"}`}>
      <div className={`h-full ${isNight ? "bg-nightMode" : "bg-lightMode"}`}>
        <div className={`bg-[#1d7df9] mb-2 text-white font-sans text-center py-2`}>
              <h1 className="text-3xl font-bold"><a href="/">BeeLine x Perlego Colour Competition</a></h1>
        </div>
        <div className="mx-4 my-2 sm:mx-10 sm:my-5 md:mx-14 md:my-5 lg:mx-36 lg:my-5 xl:mx-44 xl:my-6 2xl:mx-60 2xl:my-12">
            <Sidebar />
          <div className="flex flex-col w-full justify-normal sm:justify-center space-x-0 sm:flex-row sm:space-x-6">
              <ColorButtons hex1={hex1} setHex1={setHex1} hex2={hex2} setHex2={setHex2} isNight={isNight} setNight={setNight} isSerif={isSerif} setSerif={setSerif}/>
              <div className={"items-center sm:w-[100%] md:w-[100%] lg:w-[90%] xl:w-[80%] 2xl:w-[75%]"}>
                <Paragraph hex1={hex1} hex2={hex2} isNight={isNight} isSerif={isSerif}/>
              </div>
          </div>
        <div>
        <div className={""}>
          <Socials captureReady={captureReady} setCapture={setCapture} />
          {/* <Footer isNight={isNight}/> */}
        </div>
      </div>
      </div>
      </div>
      {captureReady && <CaptureDefaultImage hex1={hex1} hex2={hex2} isNight={isNight} isSerif={isSerif}/>}
      </div>
      </>
    );
}
let presetColors = [
    ["#4B4BFC", "#A534EC"],
    ["#0419ff", "#b10202"]
  ]
export default HomePage;