import {useState, useEffect, useRef } from "react"
import Palette from "./Palette";
import {useOutsideClick} from '../Utilities/index'


const ColorButtons = ({hex1, hex2, setHex1, setHex2, isNight, setNight, isSerif, setSerif}) =>
{
    const [showPalette, setShowPalette] = useState(false);
    const [showPalette2, setShowPalette2] = useState(false);

    const ref = useRef();
    const ref2 = useRef();

    const randomizeHex = () => {
        const randomColor1 = '#' + Math.random().toString(16).slice(2,8);
        setHex1(randomColor1);
        const randomColor2 = '#' + Math.random().toString(16).slice(2, 8);
        setHex2(randomColor2);
    }

    useOutsideClick(ref, () => {
        if (showPalette) setShowPalette(false);
    });

    useOutsideClick(ref2, () => {
        if (showPalette2) setShowPalette2(false);
    });

    useEffect(() => {
        if (showPalette || showPalette2)
        {
            document.body.style.overflow = "hidden";
        } else
        {
            document.body.style.overflow = "auto";
        }
    }, [showPalette, showPalette2])

    return (
        <div className="flex flex-row sm:flex-col mt-1 justify-evenly sm:justify-normal">
            <div
                className={`relative mt-2 sm:mb-2 rounded-md items-center h-6 w-14 sm:h-8 sm:w-16 md:h-10 md:w-24 ${isNight ? 'bg-gray-700' : 'bg-gray-300 text-gray-700'} transition-all duration-200 cursor-pointer`}
                onClick={() => setNight(!isNight)}
            >
                <div className={"w-1/2 h-full absolute right-0 top-[5%]"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                        className={`w-[90%] h-[90%] ${isNight ? 'text-white' : 'text-black'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />                </svg>
                </div>
                <div className={"w-1/2 h-full absolute left-[2%] top-[4%]"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                        className={`w-[90%] h-[90%] ${isNight ? 'text-white' : 'text-black'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                    </svg>
                </div>
                <div
                    className={`rounded-md ${isNight ? 'bg-gray-900 border border-current text-gray-300' : 'bg-gray-100'} h-full w-1/2 shadow-md transform transition-transform duration-200 ${
                        isNight ? 'translate-x-full' : 'translate-x-0'
                    }`}
                >
                </div>
            </div>
            {/* h-6 w-14 sm:h-8 sm:w-16 md:h-10 md:w-24 */}
            <div ref={ref} className="mt-2 mb-2 rounded-md cursor-pointer" value={hex1} id="hex1">
                <div className={`rounded-md h-6 w-14 sm:h-8 sm:w-16 md:h-10 md:w-24`} onClick={() => setShowPalette(!showPalette)} style={{backgroundColor:hex1}}/>
                {showPalette &&
                    <div className={""}><Palette hex={hex1} setHex={setHex1}/></div>
                }
                {/* {showPalette && <div className="w-0 mt-[0px] sm:mt-[100px] h-[0px] sm:h-[160px]" />}  */}
            </div>
            <div ref={ref2} className=" mt-2 mb-2 rounded-md cursor-pointer" value={hex2} id="hex2">
                <div className={`rounded-md h-6 w-14 sm:h-8 sm:w-16 md:h-10 md:w-24`} onClick={() => setShowPalette2(!showPalette2)} style={{backgroundColor:hex2}}/>
                {showPalette2 && <Palette hex={hex2} setHex = {setHex2}/>}
            </div>
            <div 
                className={`h-6 w-14 sm:h-8 sm:w-16 md:h-10 md:w-24 place-self-center rounded-md cursor-pointer`}
                onClick={randomizeHex}>
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    className={`w-[100%] h-[100%] fill-current ${isNight ? 'fill-white' : 'fill-black'}`}>
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"> 
                        <path fill="#fffff" d="M17.448 2.03362C17.8385 1.64315 18.4716 1.64302 18.8621 2.0334L21.4146 4.58483C22.1959 5.36584 22.1961 6.63239 21.4149 7.41355L18.8675 9.96094C18.477 10.3515 17.8438 10.3515 17.4533 9.96094C17.0628 9.57042 17.0628 8.93725 17.4533 8.54673L19 7H14.2361C13.8573 7 13.511 7.214 13.3416 7.55279L11.8954 10.4452L10.7699 8.22417L11.5528 6.65836C12.061 5.64201 13.0998 5 14.2361 5H19L17.4479 3.44791C17.0574 3.05738 17.0575 2.42415 17.448 2.03362Z"></path>
                        <path fill="#fffff" d="M17.448 14.0336C17.8385 13.6432 18.4716 13.643 18.8621 14.0334L21.4146 16.5848C22.1959 17.3658 22.1961 18.6324 21.4149 19.4136L18.8675 21.9609C18.477 22.3515 17.8438 22.3515 17.4533 21.9609C17.0628 21.5704 17.0628 20.9373 17.4533 20.5467L19 19H14.2361C13.0998 19 12.061 18.358 11.5528 17.3416L6.65836 7.55279C6.48897 7.214 6.1427 7 5.76393 7H3C2.44772 7 2 6.55228 2 6C2 5.44772 2.44772 5 3 5H5.76393C6.90025 5 7.93904 5.64201 8.44721 6.65836L13.3416 16.4472C13.511 16.786 13.8573 17 14.2361 17H19L17.4479 15.4479C17.0574 15.0574 17.0575 14.4241 17.448 14.0336Z"></path> 
                        <path fill="#fffff" d="M8.12308 13.5178L9.24864 15.7388L8.44721 17.3416C7.93904 18.358 6.90025 19 5.76393 19H3C2.44772 19 2 18.5523 2 18C2 17.4477 2.44772 17 3 17H5.76393C6.1427 17 6.48897 16.786 6.65836 16.4472L8.12308 13.5178Z"></path> 
                    </g>
                </svg>            
            </div>
            <div>
                <div
                    className="flex rounded-md bg-gray-800 hover:bg-[#1d7df9] mt-2 justify-center items-center text-center h-6 w-14 sm:h-8 sm:w-16 md:h-10 md:w-24 border border-white" 
                    onClick={() => { setSerif(!isSerif);  }}>
                    <span className={`text-white ${isSerif ? 'font-sans' : 'font-serif'}`}>Abc</span>
                </div>
            </div>
        </div>
    );
}

export default ColorButtons;