import React, { useState, useRef, useEffect } from "react";
import {promoImages} from "../Utilities/index";
// import delay from 'delay';

const Carousel = () => {
    const [current, setCurrent] = useState(0);
    const timeoutRef = useRef();

    // const nextSlide = () => {
    //     console.log("next!");
    //     console.log(current);
    //     if (current === promoImages.length - 1) setCurrent(0);
    //     else setCurrent(current + 1);
    // };

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () => {if (current === promoImages.length - 1) setCurrent(0);
        else setCurrent(current + 1)},
            4600
        );

        return () => {
            resetTimeout();
        };
    });

    return (
        <div ref={timeoutRef} className="relative overflow-hidden">
            <div className={`flex whitespace-nowrap transition ease-out duration-300`}
                style={{
                    transform: `translateX(-${current * 100}%)`,
                }}>
                {promoImages.map((p) => (
                    <img className="inline-block" key={p.id} src={p.src} alt={p.alt} />
                ))}</div>
            {/* <div>
                <button
                    className="absolute top-0 right-0 justify-center items-center flex bg-slate-500"
                    onClick={nextSlide}>
                    next
                </button>
            </div> */}
        </div>
    );
}

export default Carousel;