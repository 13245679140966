import TwitterX from "./TwitterX";
import Instagram from "./Instagram"
import Share from "./Share"

const Socials = ({captureReady, setCapture}) =>
{
    return (
        <div className="space-x-1 mt-5 flex flex-row min-[375px]:justify-center overflow-x-scroll md:overflow-x-hidden scrollbar-thin snap-align-none">
            <TwitterX/>
            <Instagram captureReady={captureReady} setCapture={setCapture}/>
            <Share /> {/* Works conditionally based on navigator.canShare()*/}
        </div>
    );
}

export default Socials;