const changeColor = (beelineRef, hex1, isNight, hex2) =>
{
    beelineRef.current.setOptions(
        {
            theme: "custom",
            customColor1: isNight ? "#ffffff" : "#0f172a",    // background color 
            customColor2: hex1,                               // first color
            customColor3: isNight ? "#ffffff" : "#0f172a",
            customColor4: hex2,                               // second color
            customBackground: isNight ? "#0f172a" : "#FFFFFF" // background color
        }
    )
      beelineRef.current.recolor();
};

const effectFunc = (beelineRef, textRef) =>
{
    if (window.BeeLineReader && !beelineRef.current) // Checks BeeLine library is imported and object isn't made yet
    {
        beelineRef.current = new window.BeeLineReader(textRef.current,
        {
            colorImmediately: true,
        });
    }
}

export {
    changeColor,
    effectFunc
}
