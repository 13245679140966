import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import TermsConditionsPage from "./Pages/TermsConditionPage";
import NotFoundPage from "./Pages/NotFoundPage";
import React, { useEffect } from "react";
import { inject } from '@vercel/analytics';

import ReactGA from "react-ga4"

let measurementID = "G-ZDVC7CSDR3"

ReactGA.initialize(measurementID);

const App = () => {
  useEffect(()=> {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname
    })    
  }, [])
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/terms" element={<TermsConditionsPage />} />
      <Route path="*" element={<NotFoundPage />}></Route>
    </Routes>
  );
};

inject();

export default App;
