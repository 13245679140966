const getTermsAndCondition = () =>
{
    let list = [
        <p>
            The promoters are: BEELINE READER, INC., a Delaware corporation, with a principal place of business in Menlo Park, CA ("<text className={"font-bold"}>BeeLine</text>") and Perlego Limited, a private limited company registered in England and Wales, whose registered address is at 138 Holborn, London, England, EC1N 2SW (“<text className="font-bold">Perlego”</text>)
        </p>,
        <p>
            The competition is open to participants aged 18 years or over.
        </p>,
        <p>
            There is no entry fee and no purchase necessary to enter this competition.
        </p>,
        <p>
            By entering this competition, an entrant is indicating his/her agreement to be bound by these terms and conditions.
        </p>,
        <p>
            Route to entry for the competition and details of how to enter are via <text className={"font-Inter"}>http:www.BeeLine-Perlego-Challenge.com</text>
        </p>,
        <p>
            Only two entries will be accepted per person. Additional entries will be void.
        </p>,
        <p>
            Closing date for entry will be Wednesday, March 27, 2024 at 6:00 PM UTC. After this time, no further entries to the competition will be accepted.
        </p>,
        <p>
            No responsibility can be accepted for entries not received for whatever reason.
        </p>,
        <p>
            The promoters reserve the right to cancel or amend the competition and these terms and conditions without notice in the event of a catastrophe, war, civil or military disturbance, act of God, or any actual or anticipated breach of any applicable law or regulation or any other event outside of the promoter’s control. Any changes to the competition will be posted here as soon as is commercially practical.
        </p>,
        <p>
            The promoter is not responsible for inaccurate prize details supplied to any entrant by any third parties.
        </p>,
        <p>
            The prizes are as follows:{'\n'}
            <p className={"font-bold ml-4"}>
            Grand Prize
            </p>{'\n'}
            <p className={"ml-8"}>
            There will be up to two Grand Prize winners, one for Day Mode (white background) and one for Night Mode (black background). Each Grand Prize winner shall receive a year of free access to the Perlego platform and a year of free access to the BeeLine Reader browser plugins for web and PDF. The color submissions made by the Grand Prize winners will be added, at a future date to be determined by the promoters, to the Perlego platform, where they will be available to all Perlego users. 
            </p>{'\n'}
            <p className={"font-bold ml-4"}>
                Finalist Prize
            </p>{'\n'}
            <p className={"ml-8"}>
            There will be up to 16 finalists, each of whom will receive a Finalist Prize of at least 3 months of free access to the BeeLine Reader browser plugins for web and PDF. Finalists that move beyond the round of 16 may, in the promoters’ sole discretion, receive more than 3 months of free access.            
            </p>
            <p className={"ml-8 mt-2"}>
            The prize is as stated and no cash or other alternatives will be offered. The prizes are not transferable. Prizes are subject to availability and we reserve the right to substitute any prize with another of equivalent value without giving notice. 
            </p>
        </p>,
        <p>
            Promoters will select the 16 finalists from among the entries received, in their sole discretion. The 16 finalists will then compete head-to-head in social media voting contests, and the winner of each duel will move on to the next round. Once there are only two remaining entries for each Day Mode and Night Mode, the promoters will select the champions for each.
        </p>,
        <p>
            The winners will be notified by email or DM on social media within 60 days of the closing date. The winner will only ever be contacted by BeeLine or Perlego. If the winner cannot be contacted or does not claim the prize within 14 days of notification, the promoters reserve the right to withdraw the prize from the winner and pick a replacement winner.
        </p>,
        <p>
            The promoters’ decisions in respect of all matters to do with the competition will be final and no correspondence will be entered into. Any abuse of the entry process or voting process, as determined in the promoters’ sole discretion, may disqualify the entrant from further consideration. Abuse may also result in blocking on social media platforms.
        </p>,
        <p>
            The competition and these terms and conditions will be governed by English law and any disputes will be subject to the exclusive jurisdiction of the courts of England.
        </p>,
        <p>
            The winners and finalists agree to the use of his/her name and image in any publicity material, including the voting competition. By way of submitting an entry in this competition, all entrants transfer to the promoters any right or interest they may have in the colours chosen for their submission. Any personal data relating to the winner or any other entrants will be used solely in accordance with current UK data protection legislation and will not be disclosed to a third party without the entrant’s prior consent. 
        </p>,
        <p>
            Current employees of the promoters are not eligible to enter submissions in this competition, but they are permitted to participate in the social media voting portion of the competition.
        </p>,
        <p>
            The winners’ names will be available 60 days after the closing date by emailing the following address: partnerships@perlego.com.
        </p>,
        <p>
            Entry into the competition will be deemed as acceptance of these terms and conditions.
        </p>,
        <p>
            This promotion is in no way sponsored or endorsed by Facebook, Twitter, TikTok, or any other Social Network.
        </p>
    ]

    return list;
}

export default getTermsAndCondition;
