import React, { useState, useRef, useEffect } from "react";
import {capture} from "../Utilities/index"

import Carousel from "./Carousel";

const Instagram = ({ captureReady, setCapture }) => {
  const [isClicked, setClick] = useState(false);
  const btnRef = useRef(null);
  const popUpRef = useRef(null);

  const handleClickOff = (event) => {
    if (popUpRef.current && !popUpRef.current.contains(event.target)) {
      if (isClicked) setClick(false);
    }
    if (btnRef.current && btnRef.current.contains(event.target)) {
      setClick(true);
      console.log("test");
    }
  };

  const handleImage = async (options) => {
    setCapture(true);

    try {
      await new Promise((resolve) => {
        setTimeout(async () => {
          await capture(document.getElementById("captureText"), {
            platform: "instagram",
            options: options,
          });
          resolve();
        }, 3000);
      });
    } catch (error) {
      console.error("Error during capture:", error);
    } finally {
    }
    setCapture(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOff);

    return () => {
      document.removeEventListener("click", handleClickOff);
    };
  });

  return (
    <div>
      <button
        ref={btnRef}
        type="button"
        data-te-ripple-init
        data-te-ripple-color="light"
        class="mb-2 flex rounded px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg
            bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-purple-600 via-pink-600 to-blue-600"
        style={{ backgroundColor: "#c13584" }}
        onClick={() => {
          setClick(!isClicked);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 h-3.5 w-3.5"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
        Submit
      </button>
      {isClicked && (
        <>
          <div className="fixed inset-0 flex items-center justify-center">
            <div
              ref={popUpRef}
              id="popup"
            //   className="bg-white relative flex flex-row rounded-2xl shadow-2xl p-1 w-[90%] h-[50%] sm:w-[60%] sm:h-[50%] md:w-[87%] md:h-[47%] lg:w-[77%] lg:h-[43%] xl:w-[50%] xl:h-[65%] 2xl:w-[45%] 2xl:h-[54%] overflow-auto"
                className="bg-white relative flex flex-row rounded-2xl shadow-2xl p-1 aspect-[4/3] w-[90%] sm:w-[60%] md:w-[87%] lg:w-[70%] xl:w-[50%] xl:h-[65%] 2xl:w-[45%] overflow-auto"

            >
              <div className="basis-[43%] mx-[-5px] my-[-5px]">
                <Carousel />
                {/* <img src={promo} alt="Example IG story post" className="bg-pink-700"></img> */}
              </div>
              {/* Nav */}
              <div className="flex flex-col p-4 basis-7/12">
                <div className={"flex justify-between"}>
                  {/* Remove close button */}
                  {/* <div className={"transparent w-7 h-4"}></div>
                        <div className={"bg-red-500 w-8 h-auto rounded-md hover:cursor-pointer hover:bg-red-600"} onClick={() => setClick(false)}>
                        </div> */}
                </div>
                <h2
                  className={
                    "font-sans text-center text-[14px] leading-[1.3] text-black font-bold md:mt-2 md:mb-2 lg:mt-4 lg:mb-4 text-lg sm:text-base md:p-1 md:text-xl lg:p-4 lg:text-2xl xl:text-2xl 2xl:p-16 2xl:text-4xl"
                  }
                >
                  Create a Winning Instagram Story!
                </h2>

                {/* Body */}
                <div className={"mt-3 px-2 sm:px-3"}>
                  <div
                    className={
                      "space-y-2 md:space-y-3 font-sans list-none text-left text-[11px] leading-[1.5] sm:text-sm sm:leading-[3.0] md:px-4 md:text-xl md:leading-[3.0] lg:px-4 lg:text-xl lg:leading-[3.0] xl:text-xl xl:leading-[2.2] 2xl:px-12 2xl:space-y-10 2xl:text-3xl 2xl:leading-[2.9]"
                    }
                  >
                    <h3 className={"leading-[1]"}>
                      Step 1: Post{" "}
                      <text
                        onClick={() => handleImage("open")}
                        className={
                          "underline text-blue-500 hover:cursor-pointer hover:text-blue-800"
                        }
                      >
                        this
                      </text>{" "}
                      image to your story!
                    </h3>
                    <h3 className={"leading-normal xl:leading[1.5rem] 2xl:leading-[2.2rem]"}>
                      Step 2: Make sure to tag{" "}
                      <text className={"font-bold "}>@Perlego</text> and{" "}
                      <text className={"font-bold"}>@BeeLineReader</text>
                    </h3>
                    <h3 className={"leading-normal xl:leading[1.5rem] 2xl:leading-[2.2rem]"}>
                      Step 3: Personalise your story to make it look great, and be sure to follow us so we can message you if you are selected as a finalist!
                    </h3>
                    {/* <h3 className={"mt-2 text-[10px] sm:text-xs"}>
                      *have your profile as public*
                    </h3> */}
                    <h3>
                      <a
                        href={"https://www.instagram.com/"}
                        target={"blank"}
                        className={
                          "underline text-blue-500 hover:cursor-pointer hover:text-blue-800"
                        }
                      >
                        Submit!
                      </a>
                    </h3>
                    <h3
                      className={"italic text-[9px] md:text-sm leading-[1] md:pl-5 mt-2 md:mt-10"}
                      style={{ zIndex: 1 }}
                    >
                      {/* Note: If you prefer to keep your account private, you can alternatively send a
                      DM @PerlegoBooks with the image to make an entry. */}
                      If your IG is set to private, please submit your entry via  <a
                        target="_blank" rel="noopener noreferrer"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdlAJ3jFPTa9bly5ZR6pci15kmffLQVQowZOsGMd_eBH__F3g/viewform?usp=sf_link"
                        className={
                          "underline text-blue-500 hover:cursor-pointer hover:text-blue-800"
                        }
                      >
                        this Google Form
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              {/* Footer */}
              <div className="absolute bottom-0"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Instagram;
