import { useRef, useEffect } from "react";
import React from "react";
import { changeColor, effectFunc, getMainText } from "../Utilities/index";

const Paragraph = ({ hex1, hex2, isNight, isSerif }) => {

  const textRef = useRef();
  const beelineRef = useRef(null);

  useEffect(() => {
    effectFunc(beelineRef, textRef);
    changeColor(beelineRef, hex1, isNight, hex2);
  }, [hex1, hex2, isNight, isSerif]);

  return (
    // max-h-[67%] sm:max-h-[79%] md:max-h-[83%] lg:max-h-[80%] xl:max-h-[80%] 2xl:max-h-[80%]
    <div className="flex flex-col w-full ">
      <p
        className={`scrollbar-thin ${
          isNight ? "scrollbar-thumb-slate-400" : "scrollbar-thumb-gray-300"
        } ${
          isNight ? "scrollbar-track-gray-500" : "scrollbar-track-gray-400"
        } text-xl leading-[1.7rem] sm:text-2xl sm:leading-[2.0rem] md:text-3xl md:leading-[2.4rem] lg:text-3xl lg:leading-[2.6rem] xl:text-4xl xl:leading-[2.9rem] 2xl:text-5xl 2xl:leading-[3.8rem] ${
          isSerif ? "font-serif" : "font-sans"
        }`}
        id="text"
        isNight={isNight ? "true" : "false"}
        ref={textRef}
      >
        {getMainText()}
      </p>
    </div>
  );
};

export default Paragraph;
