import { SITE_URL } from "../Config";

const Share = () =>
{

    let fileObj = {
        "url": SITE_URL,
        "text": "Win cash prize!",
        "title": "BeeLine and Perlego Competition!",
    }

    const share =  () => {
        if (navigator.canShare(fileObj)) {
            navigator.share(fileObj);
          }
    }

    const canShare = () =>
    {
        try
        {
            if (navigator.canShare(fileObj))
                return (
            <div>
                    <div
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        className="flex justify-center items-center overflow-hidden whitespace-nowrap text-ellipsis h-[38px] outline-2 p-1 rounded uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        style={{backgroundColor: "#121212", outline: "2px solid #FFFFFF"}}
                        onClick={share}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-1 w-[14px] h-[14px]"
                                fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                            </svg>
                            <p style={{fontSize:"10px"}} className={"mr-2"}>

                                Tell your friends
                            </p>
                    </div>
            </div>
                )
        } catch(err)
        {
            console.error("navigator.canShare() doesn't work")
            return;
        }



    }

    return(

        canShare()
    );
}

export default Share;