import html2canvas from 'html2canvas';
import encryption from './encryption';
import {API_URL} from "../Config.js"

const capture = async (textRef, settings) =>
{
  let returnMessage = {
    clipboard: false,
    openImage: false
  }
    try {
      const canvas = await html2canvas(textRef, {
        type: 'jpeg'
      });
      if (settings.options === "copy")
      {
        canvas.toBlob((blob) => {
          try {
            navigator.clipboard.write([
                new ClipboardItem({
                    'image/png': blob
                })
            ]);
            returnMessage.clipboard = true;
        } catch (error) {
            console.error("couldn't copy to clipboard");
        }
        })
      }
      const imageData = canvas.toDataURL('image/png');

      const base64Image = encodeURIComponent(imageData);
      console.log(base64Image)
      if (settings.options === "open")
      {
        let img = document.createElement("img");
        img.src = imageData;
        img.style.height = "auto"
        img.style.width = "auto"
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        var downloadLink = document.createElement('a');
        downloadLink.href = imageData;
        downloadLink.download = 'customImage.png';
        downloadLink.click();
      }
      
      // const canvas = await html2canvas(tempTextContainer);
      // document.body.removeChild(tempTextContainer);


      // const imageData = canvas.toDataURL('image/png');
  
      // const base64Image = encodeURIComponent(imageData);
      // if (settings.platform === "instagram")
      // {
      //   var image = new Image();
      //   image.src = imageData

      //   var w = window.open("")
      //   w.document.write(image.outerHTML);


      //     // var downloadLink = document.createElement('a');
      //     // downloadLink.href = imageData;
      //     // downloadLink.download = 'image.png';
      //     // document.body.appendChild(downloadLink);
      //     // downloadLink.click();
      //     // document.body.removeChild(downloadLink);

      //     return;
      //   }

        // try{
        //   const imageHash = await encryption(base64Image, hex1, hex2);

        //   const body = {
        //       Base64Image: base64Image,
        //       Hex1: hex1,
        //       Hex2: hex2,
        //       ImageHash: imageHash
        //     }

        //   const requestOptions = {
        //       method: "POST",
        //       headers: {
        //         "Content-Type": "application/json"
        //       },
        //       body: JSON.stringify(body)
        //     }
        //   const response = await fetch(API_URL, requestOptions);
        //   console.log(response)
        //   if (response.statusText ===  "OK")
        //     return (API_URL + (await response.text()).match(/"([^"]*)"/)[1])
        // } catch
        // {
        //   console.error("Error occured")
        // }
      } catch (err) {
        console.error('Error capturing image:', err);
      }
}

export default capture;
