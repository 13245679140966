import { useState, useRef } from 'react';
import React from "react";
import {useOutsideClick} from '../Utilities/index'
import beelogo from '../assets/beelinereader_logo.png';
import perlogo from '../assets/perlego_logo.png';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();

    useOutsideClick(ref, () => {
        if (isOpen) setIsOpen(false);
    });

    return (
        <>
        <div ref={ref}>
        {!isOpen ?
            (
                <button className="absolute z-30 items-center cursor-pointer right-6 top-12 md:right-10 md:top-1.5 text-white hover:text-gray-200" 
                onClick={() => setIsOpen(!isOpen)}>
                        <svg className="w-8 md:w-10 block pointer-events-none" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier"> <g id="Warning / Info"> 
                        <path id="Vector" d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> 
                        </g> 
                        </g>
                        </svg>
                </button>

                
                // <button className="fixed z-10 items-center cursor-pointer rounded-full border-2 bg-pink-400 right-10 top-6 md:top-4 text-white hover:text-gray-200"
                //     onClick={() => setIsOpen(!isOpen)}>
                //     i
                // </button>
                        // <button onClick={() => setIsOpen(!isOpen)} class="z-10 right-6 top-12 md:right-10 md:top-1.5 text-xl text-white inline-flex items-center justify-center w-10 h-10 mr-2 bg-pink-500">
                        //     <svg className="pointer-events-none" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        // </button>
            ) :
            (
                <button class="text-xl text-black fixed top-4 right-5 z-30"
                    onClick={() => setIsOpen(!isOpen)}>
                    x
                </button>
            )
        }
            <div ref={ref} className={`overflow-y-auto top-0 right-0 fixed bg-white w-5/6 md:w-[34vw] h-full p-10 grid-cols-1 z-20
            ${isOpen ? 'translate-x-0 shadow-[rgba(0,0,15,0.5)_-5px_5px_18px_0px]' : 'translate-x-full'} ease-in-out duration-300 `}>
                <div className="mb-5">
                    <img src="https://images.emojiterra.com/google/noto-emoji/unicode-15.1/color/svg/1f3c6.svg"
                        alt="An emoji trophy" className="w-14 mb-3"></img>
                        <p className="mb-3 text-[89%]">Submit a new BeeLine colour scheme (or two!) through this website to enter for a chance to win over £1,000 in prizes! We will select 16 finalists, who will meet in a head-to-head social media voting competition powered by voters like you!
                            The winning submissions (one day mode and one night mode) will receive a year of free access to (1) Perlego’s ebook platform and (2) BeeLine Reader’s browser plugins (worth nearly £200)! All finalists will receive free access to BeeLine Reader (worth up to £60). Be sure to follow us on Twitter and Instagram, where all finalists will be notified! Submissions will close Wednesday, March 27 at 6:00 PM UTC.</p>
                        <p className="text-[89%]">For more information, refer to the full contest details <a href="/terms" className="text-blue-500 hover:text-blue-800">here</a>.</p>
                </div>
                <div className="mb-5">
                    <img src={beelogo} alt="BeeLine Reader logo" className='w-14 mb-3'></img>
                        <p className="mb-3 text-[89%]">BeeLine Reader uses subtle color gradients to help you read faster and more efficiently. Love BeeLine?
                         Download our free 
                            <a href="https://apps.apple.com/us/app/beeline-extension/id1571623734" target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-800"> iOS extension </a>
                            or our tools for <a href="https://chrome.google.com/webstore/detail/beeline-reader/ifjafammaookpiajfbedmacfldaiamgg?hl=en" target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-800">Chrome</a>,
                            <a href="https://addons.mozilla.org/en-US/firefox/addon/beelinereader/" target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-800"> Firefox</a>, and 
                            <a href="https://chrome.google.com/webstore/detail/beeline-reader-pdf-viewer/pmeknoingfjncbdhempgnkdgojickcko?hl=en" target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-800"> PDF</a>!</p>
                    <p className="text-[89%]"><a href="https://www.beelinereader.com/" className="text-blue-500 hover:text-blue-800">Learn more</a></p>
                </div>
                <div className="mb-5">
                    <img src={perlogo} alt="Perlego logo" className='w-14 mb-1'></img>
                    <h2 className="mb-3 text-[89%]">Unlimited access to over 1,000,000 textbooks. Easy reading on any device with built-in
                        study tools. Sign up and start reading today!</h2>
                    <p className="text-[89%]"><a href="https://www.perlego.com/" className="text-blue-500 hover:text-blue-800">Learn more</a></p>
                </div>
                    <p className="text-center text-[78%]">Crafted with ❤️ by Izzy Lopez-Kalapir and Jaehoon Son</p>
            </div> 
         </div> 
        </>
    );
    
};

export default Sidebar;