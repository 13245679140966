import collabImg from "../assets/footer1.png";
import {useEffect, useRef} from "react";
import {effectFunc, changeColor} from "../Utilities/index";
import { SITE_URL } from "../Config";

const CaptureDefaultImage = ({hex1, hex2, isNight, isSerif}) =>
{   const beelineRef = useRef(null);
    const textRef = useRef(null);

    useEffect(()=>
    {
        effectFunc(beelineRef, textRef)
        changeColor(beelineRef, hex1, isNight, hex2);
    }, [hex1, hex2, isNight, isSerif]);

    return (
        <div id="captureText" className={`relative flex flex-col items-center justify-center pl-[30px] pr-[30px] pt-[30px] pb-[30px] w-[750px] h-[1334px] ${isNight ? "bg-[#0f172a]" : "bg-[#ffffff]"}`}>
            <p ref={textRef} className={`text-center mb-[100px] text-left text-[40px] px-10 font-[480] tracking-wider leading-snug break-words ${isNight ? "bg-[#0f172a]" : "bg-[#ffffff]"} ${isSerif ? 'font-serif' : 'font-sans'}`}>
                {`I just entered a competition to win one year of FREE access to Perlego and BeeLine Reader! All I had to do was pick out two colours for a new BeeLine colour scheme! My two colours are ${hex1} and ${hex2}.`}
                {/* {`I just entered the BeeLine & Perlego competition to win a free year of textbooks! All I had to do was this easy task… pick out colors for a new BeeLine color scheme (that’s what these gradients are all about—they help you read faster). My two colors are ${hex1} and ${hex2}, and now I have the chance to win access to a million textbooks for a year! Hooray! PS: you don’t have to make an account to enter!`}       */}
            </p>
            <p className={`text-3xl ${isNight ? "text-[#ffffff]" : "text-[#121212]"}`}>
                {`www.${SITE_URL}`}
            </p>
            <div className={`flex absolute bottom-0 ${isNight ? "bg-[#d3d3d3]" : "bg-[#ffffff]"}`}>
                <img className={`w-[100%] h-[100%] right-0 ml-[0%] mb-[0%]`} src={collabImg} alt=""/>
            </div>
        </div>
    )
}

export default CaptureDefaultImage;